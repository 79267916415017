<form [formGroup]="form" [autocomplete]="false" class="container operator-drawer">
    <div class="row">
        <div class="column" *ngIf="type === 0 || type === 1">
            <ts-text-box primaryText="{{'stopReasons.columns.code' | translate}}"
                         placeholderText="{{'stopReasons.columns.code' | translate}}" formControlName="code"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <ts-text-box primaryText="{{'stopReasons.columns.text' | translate}}"
                         placeholderText="{{'stopReasons.columns.text' | translate}}" formControlName="text"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
    </div>
    <div class="row" *ngIf="type === 0 || type === 1">
        <div class="column">
            <ts-select primaryText="{{'stopReasons.columns.category' | translate}}" [data]="errorCategories"
                       placeholderText="{{'stopReasons.columns.category' | translate}}" [clearable]="false"
                       [enableSearch]="true" [showErrors]="true" formControlName="categoryId" [value]="selectedCategory && selectedCategory.val"
                       labelKey="name" [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
        <div class="column">
            <ts-select primaryText="{{'stopReasons.columns.tag' | translate}}" [data]="errorTags"
                       placeholderText="{{'stopReasons.columns.tag' | translate}}" [clearable]="false"
                       [enableSearch]="true" [showErrors]="true" formControlName="tagId" [value]="selectedGroup.val"
                       labelKey="name" [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
    </div>

    <div class="row">
        <div class="column" *ngIf="type === 1">
            <ts-text-box primaryText="{{'stopReasons.columns.target' | translate}}"
                         placeholderText="{{'stopReasons.columns.target' | translate}}" formControlName="target"
                         [showErrors]="true" width="300px"
                         [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <div class="ts-input-container" width="300px">
                <div class="ts-input-container__header">
                    <div class="ts-input-container__header__primary">
                        <span class="ts-input-container__header__primary__text">
                            {{'stopReasons.columns.devices' | translate}}
                        </span>
                    </div>
                    <span class="ts-input-container__header__optional"></span>
                </div>
                <div class="ts-input-container__data">
                    <!--
                            Vapor sets the ts-invalid class when showErrors is true regardless of whether the
                            control is invalid and touched like other components, such as ts-autocomplete,
                            do: the long term solution would be to fix Vapor, meanwhile we check the control
                            ourselves
                        -->
                    <ts-simple-select [data]="tmpDevices" [multiSelect]="true" [clearable]="true" [enableSearch]="true"
                                      placeholderText="{{'stopReasons.columns.devices' | translate}}"
                                      formControlName="Devices" [value]="form.value.Devices[0]"
                                      [validationDict]="customValidationDict | translateJSON | async"></ts-simple-select>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="column">
            <ts-select class="select" [data]="enabledSelectData" [value]="selectedEnabledStatus" [clearable]="false"
                       primaryText="{{'devices.columns.enabled' | translate}}" formControlName="enabled"></ts-select>

        </div>
    </div>
</form>